import React, { FC } from 'react';
import { Pagination, Box, Flex } from '@endpoint/blockparty';

interface ActivityPaginationProps {
  totalPages: number;
  page: number;
  handleChange: (event: any, value: any) => void;
}

export const ActivityPagination: FC<ActivityPaginationProps> = ({ handleChange, page, totalPages }) => {
  return (
    <Flex justifyContent="center" pb="space20" pt="space80">
      <Box display={{ base: 'none', sm: 'block' }}>
        <Pagination count={totalPages} page={page} siblingCount={1} onChange={handleChange} />
      </Box>
      <Box display={{ base: 'block', sm: 'none' }}>
        <Pagination count={totalPages} page={page} siblingCount={0} onChange={handleChange} />
      </Box>
    </Flex>
  );
};
