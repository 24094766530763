import React, { FC, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Heading, Box, Button, RadioGroup, Flex, Text, Link } from '@endpoint/blockparty';
import { AppContext, AppContextType, CompanyContext } from 'utils/context';
import { Field, Formik, Form } from 'formik';
import { object, string } from 'yup';
import { FormikRadio } from 'components/form/FormikBlockparty';
import { isSsoUser } from 'utils/singleSignOn';
import { getConfig } from 'utils/config';
import { UnityRole } from 'Auth/Utilities/Constant';
import { localStorageGetItem } from 'utils/localStorageAccessor';
import { SSO_USER_INFO_KEY } from 'utils/auth/storage';
import { ButtonClickedTrackingPayload, SignUpTrackingEvents, TrackingEventType } from 'consts/analytics';
import { trackAction, trackIdentityPreCreation } from 'utils/analytics';

import { isNewLoginSystemEnabled } from '../../Utilities/helper';

export interface SignUpRoleUnityValues {
  role?: UnityRole;
}

export const SignUpRoleUnitySchema = object().shape({
  role: string().required('Role is Required'),
});

const roleOptions = [
  {
    name: 'Agent',
    value: UnityRole.AGENT,
  },
  {
    name: 'Transaction Coordinator',
    value: UnityRole.TRANSACTION_COORDINATOR,
  },
  {
    name: 'Buyer',
    value: UnityRole.BUYER,
  },
  {
    name: 'Seller',
    value: UnityRole.SELLER,
  },
  {
    name: 'Borrower',
    value: UnityRole.BORROWER,
  },
];

export const SignUpRoleUnity: FC = () => {
  const { setUser, user }: AppContextType = useContext(AppContext);
  const { companyName } = useContext(CompanyContext);
  const { PRIVACY_URL } = getConfig();
  const SUBMIT_BUTTON_TEXT = 'Continue';

  const [isSso, setIsSso] = useState<boolean>(false);

  const handleRoleSubmit = ({ role }: SignUpRoleUnityValues) => {
    const trackSelectRoleSubmitProperties: ButtonClickedTrackingPayload = {
      name: TrackingEventType.BUTTON_CLICKED,
      buttonId: SUBMIT_BUTTON_TEXT,
    };

    trackAction(SignUpTrackingEvents.CONTINUE_BUTTON_SUBMITTED, trackSelectRoleSubmitProperties);
    trackIdentityPreCreation({ onboardingRole: role });
    setUser({ ...user, isOnboarded: false, unityRole: role });
  };

  useEffect(() => {
    async function getCognitoUser() {
      const currentUser = await Auth.currentAuthenticatedUser();

      if (isSsoUser(currentUser)) {
        setIsSso(true);
      }
    }

    // TODO: revisit after https://apex-venture.atlassian.net/browse/PX-3177
    if (!isNewLoginSystemEnabled()) {
      void getCognitoUser();
    } else if (localStorageGetItem(SSO_USER_INFO_KEY)) {
      setIsSso(true);
    }
  }, []);

  return (
    <Formik initialValues={{ role: undefined }} validationSchema={SignUpRoleUnitySchema} onSubmit={handleRoleSubmit}>
      {({ isValid, dirty, values }) => (
        <Form>
          <Heading as="h2" mb="space50" size={{ base: 'fontSize70', md: 'fontSize60' }}>
            Please select your role
          </Heading>

          <Box fontSize="fontSize20" lineHeight="lineHeight20" mb="space70">
            Welcome to {companyName}! Please select your role from the choices below.
          </Box>

          <Box mb="space30">
            <RadioGroup
              aria-label="sign-up-role"
              defaultValue={values.role}
              mb="space80"
              name="role"
              role="group"
              spacing="space50"
            >
              {roleOptions.map(({ name, value }) => {
                return (
                  <Field
                    key={name}
                    component={FormikRadio}
                    id={name}
                    mb={{ base: 'space50', lg: 'space0' }}
                    name="role"
                    value={value}
                  >
                    {name}
                  </Field>
                );
              })}
            </RadioGroup>
          </Box>

          {isSso && (
            <>
              <Box mb="space80">
                <Text>
                  By clicking “Continue”, you agree to {companyName}’s{' '}
                  <Link color="blue500" cursor="pointer" href={PRIVACY_URL} target="_blank" textDecoration="underline">
                    Privacy & Terms
                  </Link>
                </Text>
              </Box>
            </>
          )}

          <Flex
            backgroundColor="white"
            bottom={{ base: 0, md: 'initial' }}
            boxShadow={{ base: 'medium', md: 'none' }}
            justifyContent="flex-end"
            left={{ base: 0, md: 'initial' }}
            position={{ base: 'fixed', md: 'initial' }}
            px={{ base: 'space50', md: 'space0' }}
            py={{ base: 'space30', md: 'space0' }}
            right={{ base: 0, md: 'initial' }}
            zIndex="overlay"
          >
            <Button isDisabled={!(isValid && dirty)} type="submit">
              {SUBMIT_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
