import { useTransactionPayload } from 'hooks/useTransactionPayload';
import { useListTransactions } from 'hooks/useListTransactions';
import { TransactionStatus } from '@endpoint/endpoint-bff-graphql-schema';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const useNumberOfTransactions = () => {
  const { isEscrowPaginationEnabled } = useFeatureFlags();

  const { transactionPayloadData } = useTransactionPayload({ skipQuery: isEscrowPaginationEnabled ?? true });
  const { totalItems } = useListTransactions({
    page: 1,
    status: TransactionStatus.IN_ESCROW,
    skipQuery: !isEscrowPaginationEnabled,
  });
  const numberOfTransactions = isEscrowPaginationEnabled ? totalItems : transactionPayloadData?.myTransactions.length;

  return {
    numberOfTransactions: numberOfTransactions ?? 0,
  };
};
