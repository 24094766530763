import { gql } from '@apollo/client';
import { PaginatedInAppNotification } from '@endpoint/platform-api-connector/dist/graphql-types';

export const GET_USER_NOTIFICATIONS = gql`
  query getUserNotifications($where: InAppNotificationInput) {
    myInAppNotificationsPaginated(where: $where) {
      cursor
      notifications {
        id
        text
        link
        createdAt
        principalId
        principalName
        viewed
        transaction {
          id
          property {
            propertyImage
            address {
              standardFormat
              street1
              street2
              city
              state
              zip
              county
            }
          }
        }
      }
    }
  }
`;

export interface NotificationsPayload {
  myInAppNotificationsPaginated: PaginatedInAppNotification;
}

/* ======================================= */

export const VIEW_USER_NOTIFICATIONS = gql`
  mutation markNotificationsAsViewed {
    markMyInAppNotificationsAsViewed
  }
`;
