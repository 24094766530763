import { gql } from '@apollo/client';

export const GET_TRANSACTION_ACTIVITY = gql`
  query getTransactionActivity($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      fileNum
      createdAt
      property {
        address {
          standardFormat
          street1
          street2
          city
          state
          zip
        }
        propertyImage
      }
      type
      myActivityEntries {
        id
        text
        link
        createdAt
        milestone
        principalId
        principalName
      }
    }
  }
`;
