import { useState, useEffect } from 'react';
import { getOrganizationDetails } from 'utils/getOrganizationData';
import { getCompanyDefaults, getConfig } from 'utils/config';

const useOrganization = () => {
  const { ORGANIZATION_ID } = getConfig();
  const { COMPANY_SHORTNAME, COMPANY_BRANDNAME } = getCompanyDefaults();
  const [organization, setOrganization] = useState({
    id: ORGANIZATION_ID,
    shortName: COMPANY_SHORTNAME,
    brandName: COMPANY_BRANDNAME,
  });

  useEffect(() => {
    const fetchData = async () => {
      const org = await getOrganizationDetails();

      if (Boolean(org.shortName.trim()) && Boolean(org.brandName.trim())) {
        setOrganization({
          id: org.id,
          shortName: org.shortName,
          brandName: org.brandName,
        });
      }
    };

    void fetchData();
  }, []);

  return organization;
};

export default useOrganization;
