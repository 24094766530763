import React from 'react';
import { Flex, Text } from '@endpoint/blockparty';

export const NoActivity = () => (
  <Flex alignItems="center" data-test-id="no-activity" flexDirection="column" justifyContent="center" py="space70">
    <Text color="carbon500" mb="space60" size="fontSize50">
      There is no recent activity.
    </Text>
    <Text color="carbon500">All activity from this transaction will display here.</Text>
  </Flex>
);

NoActivity.displayName = 'NoActivity';
