import React, { FC } from 'react';
import { Flex, Divider, Text } from '@endpoint/blockparty';
import { Svg } from 'components/Svg';

export const NoNewNotifications: FC = () => (
  <>
    <Flex alignItems="center" px="space70" py="space60">
      <Svg aria-label="thumbs up" height="40px" name="ThumbsUp" width="40px" />
      <Flex flexDirection="column" ml="space50">
        <Text color="carbon500" fontWeight="semi" size="fontSize10">
          You’re all caught up!
        </Text>
        <Text color="carbon500" mt="space20" size="fontSize10">
          Check back here for new notifications.
        </Text>
      </Flex>
    </Flex>
    <Divider />
  </>
);
