import React, { FC } from 'react';
import { EPContent, EPSidebar } from '@endpoint/blockparty';

import { ActivityDetailsSkeleton } from './ActivityDetailsSkeleton';
import { ActivityListSkeleton } from './ActivityListSkeleton';
import { PropertyHeaderSkeleton } from '../PropertyHeaderSkeleton';

export const ActivitySkeleton: FC = () => (
  <>
    <PropertyHeaderSkeleton />

    <EPSidebar>
      <ActivityDetailsSkeleton />
    </EPSidebar>

    <EPContent>
      <ActivityListSkeleton />
    </EPContent>
  </>
);
