import React, { FC, useCallback } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Icon, Stack } from '@endpoint/blockparty';
import { openChatWindow } from 'utils/zendesk';
import { useAppContext } from 'utils/context';
import { trackAction } from 'utils/analytics';
import { ChatEvents } from 'consts/analytics';
import { useGetZendeskTag } from 'hooks/useGetZendeskTag';
import { TODO_ASSIGNMENT_WITH_STEP } from 'consts/routes';

import { HeaderNotifications } from '../HeaderNotifications';
import { HeaderAvatar } from '../HeaderAvatar';

export const HeaderRight: FC = () => {
  const { user } = useAppContext();
  const { pathname } = useLocation();
  const isViewingTodo = matchPath(TODO_ASSIGNMENT_WITH_STEP, pathname);
  const showNotifications = !isViewingTodo && user.showNotificationsHeaderIcon;
  const zendeskTag = useGetZendeskTag(user.lastTransactionViewed?.marketId?.valueOf());
  const openChat = useCallback(() => {
    trackAction(ChatEvents.OPEN_CHAT_NAV_BAR);
    openChatWindow(user, zendeskTag);
  }, [user, zendeskTag]);

  return (
    <Stack alignItems="center" direction="row" height="100%" spacing="space60">
      <Icon
        color="background"
        cursor="pointer"
        data-test-id="zendesk-icon"
        name="ChatHelp"
        size="large"
        onClick={openChat}
      />
      {showNotifications && <HeaderNotifications />}
      <HeaderAvatar />
    </Stack>
  );
};
