import React, { FC } from 'react';
import { Flex, Text } from '@endpoint/blockparty';
import { Svg } from 'components/Svg';

export const NoNotifications: FC = () => (
  <Flex alignItems="center" data-test-id="no-notifications" flexDirection="column" pb="space60" pt="space70">
    <Svg aria-label="no new notifications" height="48px" ml="10px" name="NoNotifications" width="48px" />
    <Text color="carbon500" mt="space50" size="fontSize30">
      You have no notifications yet
    </Text>
    <Text color="carbon500" mt="space20" size="fontSize10">
      All notifications relating to your transaction will appear here.
    </Text>
  </Flex>
);
