import { CompanyName } from 'utils/whiteLabel/types';

type CompanyEmail = {
  [key in CompanyName]: string;
};

const companyEmails: CompanyEmail = {
  Endpoint: 'hello@endpoint.com',
  AHC: 'hello@accesshomeclosing.com',
  QETestOrg: 'hello@qetestorg.com',
  QETestOrg2: 'hello@qetestorg.com',
  OpsTest: 'hello@endpoint.com',
};

export const getCompanyEmail = (key: CompanyName) => {
  return companyEmails[key];
};
