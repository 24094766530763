import React, { FC, useEffect, useState, Fragment } from 'react';
import { EPContent, Heading, Text, Box, RadioGroup, Flex, Button, Collapse, Link } from '@endpoint/blockparty';
import { ANCHORED_BUTTON_OFFSET } from 'consts/responsive';
import { Field, Formik, Form } from 'formik';
import { object, string } from 'yup';
import { FormikGroup, FormikRadio, FormikTextArea } from 'components/form/FormikBlockparty';
import { AnchoredButton } from 'components/AnchoredButton';
import { useNavigate } from 'react-router';
import { SETTINGS_NOTIFICATION, SETTINGS_PROFILE } from 'consts/routes';
import { AlertMessage } from 'components/AlertMessage';
import { useAppContext } from 'utils/context';
import { useUpdateContact } from 'hooks/useUpdateContact';
import { RouteOptions } from 'Auth/Utilities/Constant';
import { clearTokens } from 'utils/auth/storage';
import { ProfileTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import useOrganization from 'hooks/useOrganization';
import { useGetActiveFileNumbers } from 'hooks/useGetActiveFileNumbers';
import { getConfig } from 'utils/config';

export interface ProfileCancelValuesInput {
  cancelType: string;
  feedback: string;
}

interface CancellationSegmentPackage extends ProfileCancelValuesInput {
  firstName?: string;
  lastName?: string;
  email?: string;
  contactType?: string;
  reswareFileNumber?: string[];
}

export const ProfileCancellationSchema = object().shape({
  cancelType: string().required('Cancel type is Required'),
  feedback: string(),
});

export const ProfileCancellation: FC = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const { updateContact, updatedContact } = useUpdateContact();
  const { getActiveFileNumbers, activeFileNumbers } = useGetActiveFileNumbers();
  const { brandName } = useOrganization();
  const { DATA_PRIVACY_EMAIL } = getConfig();

  const cancellationOptions = [
    {
      name: "I'm getting too many emails/notifications",
      value: 'tooManyNotifications',
    },
    {
      name: 'I will be using an offline account',
      value: 'offline',
    },
    {
      name: `I no longer need to use ${brandName}`,
      value: 'noLongerNeeded',
    },
    {
      name: 'Other',
      value: 'other',
    },
  ];

  useEffect(() => {
    getActiveFileNumbers();
  }, [getActiveFileNumbers]);

  useEffect(() => {
    async function logOut() {
      await clearTokens();
      navigate(RouteOptions.SIGN_UP, { state: { isRecentlyDeactivated: true } });
    }

    if (updatedContact && updatedContact.isDeactivated) {
      void logOut();
    }
  }, [navigate, updatedContact]);

  const handleCancelSubmit = async (response: ProfileCancelValuesInput) => {
    try {
      if (user.id) {
        const deactivationRequest = { isDeactivated: true };

        const fileNumbers = activeFileNumbers?.getActiveFileNumbers;

        const trackActionInfo: CancellationSegmentPackage = {
          cancelType: response.cancelType,
          feedback: response.feedback,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          contactType: user.contactType,
          reswareFileNumber: fileNumbers,
        };

        trackAction(ProfileTrackingEvents.PROFILE_CANCEL, trackActionInfo);

        await updateContact({
          variables: { where: { id: user.id }, input: deactivationRequest },
        });
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const isNotificationSelected = (option: string) => option === "I'm getting too many emails/notifications";

  return (
    <Formik
      initialValues={{ cancelType: '', feedback: '' }}
      validationSchema={ProfileCancellationSchema}
      onSubmit={handleCancelSubmit}
    >
      {({ isValid, dirty, values }) => (
        <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }}>
          <Form>
            <Heading mb="space50" size="fontSize40">
              Deactivate Your Account
            </Heading>
            {hasError && (
              <Box mb="space70">
                <AlertMessage
                  description="Please try again."
                  title="Oops! We’ve encountered a slight hiccup in completing your request."
                />
              </Box>
            )}
            <Text as="p" data-test-id="deactivation-text-message" mb="space70">
              We&apos;re sorry to see you go! By deactivating your account you will immediately be logged out and no
              longer be able to log into your {brandName} account. If you wish to request removal of your personal data
              please email{' '}
              <Link
                color="blue500"
                cursor="pointer"
                href={`mailto:${DATA_PRIVACY_EMAIL}`}
                isExternal
                textDecoration="underline"
              >
                {DATA_PRIVACY_EMAIL}
              </Link>
              .
            </Text>
            <Text as="p" fontWeight="semi" mb="space70" size="fontSize30">
              Please select why you&apos;re deactivating your account:
            </Text>
            <Box data-test-id="box-cancel-choices" mb="space50">
              <RadioGroup
                aria-label="cancel-options"
                defaultValue={values.cancelType}
                id="radio-group"
                name="cancelType"
                role="group"
              >
                {cancellationOptions.map(({ name, value }) => {
                  if (isNotificationSelected(name)) {
                    return (
                      <Fragment key={`${value}-${name}`}>
                        <Field
                          component={FormikRadio}
                          id={name}
                          mb={{ base: 'space50', md: 'space0' }}
                          name="cancelType"
                          value={name}
                        >
                          {name}
                        </Field>
                        <Collapse
                          data-test-id="collapse-notification-text"
                          isOpen={isNotificationSelected(values.cancelType)}
                          mb={{ base: 'space40', md: 'space20' }}
                          ml="space60"
                          px="space20"
                        >
                          <Text color="carbon500">
                            To adjust the settings of your notifications, please visit the{' '}
                            <Link
                              color="blue500"
                              cursor="pointer"
                              data-test-id="settings-notification-link"
                              textDecoration="underline"
                              onClick={() => navigate(SETTINGS_NOTIFICATION)}
                            >
                              <Text as="p" color="blue500" display="inline">
                                Notifications
                              </Text>
                            </Link>{' '}
                            page.
                          </Text>
                        </Collapse>
                      </Fragment>
                    );
                  }

                  return (
                    <Field key={`${value}-${name}`} component={FormikRadio} id={name} name="cancelType" value={name}>
                      {name}
                    </Field>
                  );
                })}
              </RadioGroup>
            </Box>
            <Box data-test-id="box-cancel-comment">
              <Text as="p" mb="space50">
                Your feedback is important to us. Is there anything else you&apos;d like us to know?
              </Text>
              <FormikGroup
                component={FormikTextArea}
                id="cancel-text-area"
                label=" "
                name="feedback"
                placeholder="(optional)"
              />
            </Box>
            <AnchoredButton>
              <Flex justifyContent="flex-end">
                <Button data-test-id="cancel" mr="space50" variant="outline" onClick={() => navigate(SETTINGS_PROFILE)}>
                  Cancel
                </Button>
                <Button data-test-id="submit" isDisabled={!(isValid && dirty)} type="submit">
                  Deactivate Your Account
                </Button>
              </Flex>
            </AnchoredButton>
          </Form>
        </EPContent>
      )}
    </Formik>
  );
};
