import { getConfig } from '../config';

const { ORGANIZATION_ID, BFF_URL } = getConfig();

const organization = {
  id: ORGANIZATION_ID,
  shortName: '',
  brandName: '',
};
let fetchPromise: undefined | Promise<void>;
let didFetch = false;

export const query = `
  query GetOrganizationByDomainShortname($input: GetOrganizationByDomainShortnameInput!) {
    getOrganizationByDomainShortname(input: $input) {
      id
      brandName
      shortName
    }
  }
`;

export function getDomain() {
  const hostParts = window.location.hostname.split('.');
  const len = hostParts.length;

  return `${hostParts[len - 2] ? `${hostParts[len - 2]}.` : ''}${hostParts[len - 1]}`;
}

const variables = {
  input: {
    domain: getDomain(),
  },
};

async function fetchOrganizationData() {
  try {
    const response = await fetch(BFF_URL, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    const responseJson = await response.json();
    const organizationData = responseJson?.data?.getOrganizationByDomainShortname;

    organization.shortName = organizationData?.shortName || '';
    organization.brandName = organizationData?.brandName || '';

    if (!Number.isNaN(parseFloat(organizationData?.id))) {
      organization.id = organizationData?.id;
    }
  } catch (_err) {
    organization.id = ORGANIZATION_ID;
  } finally {
    didFetch = true;
  }
}

export async function getOrganizationId() {
  if (didFetch) {
    return organization.id;
  }

  if (!fetchPromise) {
    fetchPromise = fetchOrganizationData();
  }

  await fetchPromise;

  return organization.id;
}

export async function getOrganizationDetails() {
  if (didFetch) {
    return organization;
  }

  if (!fetchPromise) {
    fetchPromise = fetchOrganizationData();
  }

  await fetchPromise;

  return organization;
}
