export const replacePathname = (url: string, currentPath: string, newPath: string) => {
  return url.replace(currentPath, newPath);
};

// TODO: Remove when Platform API updates link field in Notifications entity
export const convertLinkToNewPath = (link: string) => {
  const url = new URL(link);
  const path = url.pathname;

  // To-Do
  if (path.includes('tasks/')) {
    return replacePathname(path, 'tasks', 'todo');
  }

  // Transaction Detail
  if (path.includes('tasks')) {
    return replacePathname(path, 'tasks', 'todos');
  }

  // Open Prelim
  if (path.includes('openPreliminaryTitle')) {
    return replacePathname(path, 'openPreliminaryTitle', 'order-reports');
  }

  // Open Escrow
  if (path.includes('newTitleAndEscrow')) {
    return replacePathname(path, 'newTitleAndEscrow', 'open-escrow');
  }

  // Prelim (processing) -> Documents
  if (path.includes('prelim')) {
    return replacePathname(path, 'prelim', 'documents');
  }

  // Documents, Contacts, Home, etc
  return path;
};

export const removeUndefinedFromString = (standardFormat: string | undefined | null): string => {
  if (!standardFormat) return '';

  const addressArray = standardFormat.split(' ');
  const cleanedAddressArray = addressArray.filter((str) => !str.includes('undefined'));

  return cleanedAddressArray.join(' ');
};
