import { FetchResult } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { SSOInput, SsoAuthResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { useSSOMutation } from 'hooks/auth/SSO/useSSOMutation';
import { useAuth } from 'hooks/auth/useAuth';
import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getOrganizationId } from 'utils/getOrganizationData';
import { getSSOCallbackUrl } from 'utils/singleSignOn';
import { localStorageSetItem } from 'utils/localStorageAccessor';
import { SSO_USER_INFO_KEY } from 'utils/auth/storage';

export const GRANT_TYPE = 'authorization_code';

async function exchangeCodeForToken(
  code: string,
  state: string,
  SSO: (input: SSOInput) => Promise<FetchResult<{ sso: SsoAuthResponse }>>,
): Promise<SsoAuthResponse | null> {
  try {
    const [organizationId, callbackUrl] = await Promise.all([getOrganizationId(), getSSOCallbackUrl()]);

    const { data } = await SSO({
      code,
      state,
      grantType: GRANT_TYPE,
      organizationId,
      callbackUrl,
    });

    if (!data?.sso.authentication) return null;

    return data.sso;
  } catch (error: unknown) {
    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      message: `exchangeCodeForToken Error: ${(error as Error).message}`,
    });

    return null;
  }
}

function SSOCallbackHandler() {
  const [searchParams] = useSearchParams();
  const { setTokens } = useAuth();
  const { SSO } = useSSOMutation();
  const { search } = useLocation();

  const handleSSOCallback = useCallback(async () => {
    const [code, state] = [searchParams.get('code'), searchParams.get('state')];

    if (!code || !state) return;

    const exchangeResponse = await exchangeCodeForToken(code, state, SSO);

    if (!exchangeResponse?.authentication) return;

    setTokens(exchangeResponse.authentication);

    localStorageSetItem(SSO_USER_INFO_KEY, JSON.stringify(exchangeResponse.user));
  }, [searchParams, setTokens, SSO]);

  useEffect(() => {
    handleSSOCallback();
    // we need to only run this code when `search` changes
    // otherwise we'll trigger an infinite loop exchanging the token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return null;
}

export default SSOCallbackHandler;
