import { useState, useEffect } from 'react';
import { getConfig } from 'utils/config';
import { getOrganizationId } from 'utils/getOrganizationData';

const { ORGANIZATION_ID } = getConfig();

// Returns organizationId from the organization-service's database of domains, otherwise
// returns the default organizationId from the config.

const useOrganizationId = () => {
  const [organizationId, setOrganizationId] = useState<number>(ORGANIZATION_ID);

  useEffect(() => {
    const fetchOrganizationId = async () => {
      const orgId = await getOrganizationId();

      setOrganizationId(orgId);
    };

    void fetchOrganizationId();
  }, []);

  return organizationId;
};

export default useOrganizationId;
