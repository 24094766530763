import { RouteLabels as HeaderRouteLabels } from 'components/Header/routes';
import { CompanyName } from 'utils/whiteLabel/types';

import { MFA_RESEND_CODE_LINK_TEXT, MFA_SUBMIT_BUTTON_TEXT, SET_PROFILE_CONTINUE_BUTTON_TEXT } from './descriptions';

// Segment Event tracking //
export enum TransactionTrackingEvents {
  REPORT_BY_ADDRESS = 'Open Order Pre-Listing Report By Property Address',
  REPORT_BY_PARCEL = 'Open Order Pre-Listing Report By Parcel Number',
  OPEN_ESCROW_BY_CONVERTING_PRELIM = 'Convert Prelim Selected',
  OPEN_ESCROW_FROM_TRANSACTION_CARD = 'Convert Prelim from Transaction Card',
  OPEN_NEW_ESCROW = 'New Escrow Order Selected',
  UPLOAD_PURCHASE_AGREEMENT = 'Escrow PSA Uploaded',
  OPEN_ESCROW_SUBMITTED = 'Open Escrow Order Submitted',
  PRELIM_VIEW_REPORTS = 'Prelim - View Reports',
}

export const getReportSubmissionAction = (companyName: CompanyName) => `Report -- Submit to ${companyName}` as const;

export const getAddedToEscrowOrderAction = (title: String) => `${title} Added to Escrow Order` as const;

export enum ChatEvents {
  OPEN_CHAT_CONTACT = 'Chat from contacts Opened',
  OPEN_CHAT_NAV_BAR = 'Chat from Nav Bar Opened',
  OPEN_CHAT_PROFILE = 'Chat from Profile Opened',
  HIDE_CHAT = 'Hide Chat',
  END_CHAT = 'End Chat',
  START_CHAT = 'Starts Chat',
}

export type TrackingEvents =
  | TodoTrackingEvents
  | DocumentTrackingEvents
  | ProfileTrackingEvents
  | ProfileMenuTrackingEvents
  | NotificationTrackingEvents
  | MFATrackingEvents
  | SignInTrackingEvents
  | SignUpTrackingEvents
  | ChatEvents
  | TransactionTrackingEvents
  | ReturnType<typeof getReportSubmissionAction>
  | ReturnType<typeof getAddedToEscrowOrderAction>
  | EMDTrackingEvents
  | HeaderRoutesTrackingEvents
  | GenericTrackingEvents;

export enum TodoTrackingEvents {
  TODO_EDIT = 'To-Do Edit',
  TODO_NEXT_STEP = 'To-Do Next Step',
  TODO_ANSWER_CHOSEN = 'To-Do Answer Chosen',
  TODO_OPENED = 'To-Do Opened',
  TODO_PREVIOUS_STEP = 'To-Do Previous Step',
  TODO_SAVE_AND_EXIT = 'To-Do Save and Exit',
  TODO_STARTED = 'To-Do Started',
  TODO_SUBMITTED = 'To-Do Submitted',
  TRACK_NEXT_MILESTONE = 'Next Milestone Viewed',
  TRACK_PREVIOUS_MILESTONE = 'Previous Milestone Viewed',
  CLICKED_PINNED_ITEM = 'Pinned Item Clicked',
}

export enum TodoEMDAnswerKeys {
  INTEREST_BEARING_OPTION = 'interest_bearing_option',
  PAYMENT_METHOD_CHOICE = 'payment_method_choice',
  NON_ONLINE_PAYMENT_METHOD_CHOICES = 'non_online_payment_method_choices',
  DWOLLA_DEPOSIT = 'depositId',
}

export enum EMDTrackingEvents {
  EMD_INTEREST_BEARING_ACCOUNT = 'Create Interest Bearing Account',
  EMD_PAY_ONLINE = 'EMD Pay Online',
  EMD_PAY_BY_CHECK = 'EMD Pay by Check',
  EMD_PAY_BY_WIRE = 'EMD Pay by Wire',
  EMD_PAY_BY_MOBILE = 'EMD Pay by Mobile Check Deposit',
  EMD_PAY_ALREADY_SUBMITTED = 'EMD Payment Already Submitted',
  EMD_KBA_STARTED = 'EMD KBA Started',
  EMD_KBA_COMPLETED = 'EMD KBA Completed',
  EMD_KBA_FAILED = 'EMD KBA Failed',
  EMD_RETRY_STARTED = 'EMD Retry Started',
  EMD_INITIAL_VERIFICATION_FAILED = 'EMD Initial Verification Failed',
  EMD_INITIAL_VERIFICATION_STARTED = 'EMD Initial Verification Started',
  EMD_INITIAL_VERIFICATION_COMPLETED = 'EMD Initial Verification Completed',
  DWOLLA_CUSTOMER_CREATED = 'Dwolla Customer Created',
  ONLINE_ACH_TRANSFER_SUCCESSFUL = 'Online ACH Transfer Successful',
  ONLINE_ACH_TRANSFER_FAILURE = 'Online ACH Transfer Failure',
}

export enum DocumentTrackingEvents {
  VIEW_DOC = 'Document Viewed',
  VIEW_DOC_NOTE = 'Document Note Viewed',
  UPLOAD_DOC = 'Document Uploaded',
  DOWNLOAD_DOC = 'Document Downloaded',
}

export enum ProfileTrackingEvents {
  PROFILE_EDIT = 'Profile Edit Requested',
  PROFILE_CANCEL = 'Profile Cancel Requested',
}

export enum NotificationTrackingEvents {
  EMAIL_ENABLED = 'Email Notifications Turned On',
  EMAIL_DISABLED = 'Email Notifications Turned Off',
  VIEWED_IN_APP_NOTIFICATIONS = 'Viewed In-App Notifications',
  IN_APP_NOTIFICATIONS_CLICKED = 'In-App Notifications Clicked',
}

export enum GenericTrackingEvents {
  ERROR_VIEWED = 'Error Viewed',
}

// Segment Page tracking

export enum TrackingPages {
  TODO_DETAIL = 'TODO DETAIL',
  TRANSACTIONS_IN_ESCROW = 'TRANSACTIONS - IN-ESCROW',
  TRANSACTIONS_LISTING = 'TRANSACTIONS - LISTING',
  TRANSACTIONS_CLOSED = 'TRANSACTIONS - CLOSED',
  TRANSACTION_TODOS = 'TRANSACTION - TODOS',
  TRANSACTION_ACTIVITY = 'TRANSACTION - ACTIVITY',
  TRANSACTION_DOCUMENTS = 'TRANSACTION - DOCUMENTS',
  TRANSACTION_CONTACTS = 'TRANSACTION - CONTACTS',
  PROFILE = 'PROFILE',
  NOTIFICATION_SETTINGS = 'NOTIFICATION SETTINGS',
  OPEN_ESCROW = 'OPEN ESCROW',
  ORDER_REPORTS = 'ORDER REPORTS',
  SIGN_IN_MFA = 'Sign In 2FA',
  SIGN_UP_MFA = 'Sign Up 2FA',
  CREATE_NEW_ACCOUNT = 'Create New Account',
  VERIFICATION_NEEDED = 'Verification Needed',
  SET_PROFILE = 'Set Profile',
  SELECT_ROLE = 'Select Role',
  NOTIFICATION_PREFERENCES = 'Notification Preferences',
}

export enum ProfileMenuTrackingEvents {
  PROFILE_ICON_CLICKED = 'Profile Icon Clicked',
}

type HeaderRoutesTrackingEvents = `${HeaderRouteLabels} Clicked`;

export enum MFATrackingEvents {
  RESEND_MFA_CODE = 'Resent 2FA code',
  SUBMITTED_MFA_CODE = '2FA code Submitted',
  MAX_MFA_ATTEMPTS_REACHED = 'Max MFA Attempts Reached',
}

export enum SignInTrackingEvents {
  EMAIL_AND_PASSWORD_SUBMITTED = 'Email and Password Submitted',
  CLICKED_FORGOT_EMAIL_OR_PASSWORD = 'Forgot Email or Password Clicked',
  CLICKED_SIGN_UP_LINK = 'Sign up Clicked',
  KEEP_USER_SIGNED_IN = 'Keep User Signed In Clicked',
  MAX_LOGIN_ATTEMPTS_REACHED = 'Max Login Attempts Reached',
  FORGOT_PASSWORD_SELECTED = 'Selected Forgot Password',
  FORGOT_EMAIL_SELECTED = 'Selected Forgot Email ',
  ACCOUNT_CREATED = 'Account Created',
  ACCOUNT_SIGNED_IN = 'Account Signed In',
}

export enum SignUpTrackingEvents {
  CONTINUE_BUTTON_SUBMITTED = 'Continue Submitted',
  PRIVACY_AND_TERMS_LINK = 'Privacy and Terms Clicked',
  SIGN_IN_LINK = 'Sign In Clicked',
  SET_PROFILE_BUTTON_SUBMITTED = 'Profile Submitted',
  CREATE_ACCOUNT_SUBMITTED = 'Create Account Submitted',
}

export enum TrackingEventType {
  LINK_CLICKED = 'Link Clicked',
  BUTTON_CLICKED = 'Button Clicked',
  CHECKBOX_CHECKED = 'Checkbox Checked',
  CHECKBOX_UNCHECKED = 'Checkbox Unchecked',
}

type CheckboxTrackingEvent = TrackingEventType.CHECKBOX_CHECKED | TrackingEventType.CHECKBOX_UNCHECKED;
export interface ButtonClickedTrackingPayload {
  name: TrackingEventType.BUTTON_CLICKED;
  buttonId: string;
}
export interface LinkClickedTrackingPayload {
  name: TrackingEventType.LINK_CLICKED;
  linkId: string;
}

export interface CheckboxCheckedTrackingPayload {
  name: CheckboxTrackingEvent;
  checkboxId: string;
}

export interface GenericTrackingPayload {
  name: string;
}

export const TRACK_MFA_SUBMIT_PAYLOAD: ButtonClickedTrackingPayload = {
  name: TrackingEventType.BUTTON_CLICKED,
  buttonId: MFA_SUBMIT_BUTTON_TEXT,
};

export const TRACK_MFA_RESEND_CODE_PAYLOAD: LinkClickedTrackingPayload = {
  name: TrackingEventType.LINK_CLICKED,
  linkId: MFA_RESEND_CODE_LINK_TEXT,
};

export const TRACK_SET_PROFILE_PAYLOAD: ButtonClickedTrackingPayload = {
  name: TrackingEventType.BUTTON_CLICKED,
  buttonId: SET_PROFILE_CONTINUE_BUTTON_TEXT,
};

export const TRACK_GENERIC_ERROR_VIEWED_PAYLOAD: GenericTrackingPayload = {
  name: 'Error Viewed',
};
