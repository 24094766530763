import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Divider,
  Flex,
  Icon,
  Loading,
  PopoverTriggerAlpha,
  PopoverAlpha,
  Text,
  usePopoverStateAlpha,
} from '@endpoint/blockparty';
import { useQuery, useMutation, OperationVariables } from '@apollo/client';
import { InAppNotificationWithTransaction } from '@endpoint/platform-api-connector/dist/graphql-types';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { trackAction } from 'utils/analytics';
import { NotificationTrackingEvents } from 'consts/analytics';

import { GET_USER_NOTIFICATIONS, VIEW_USER_NOTIFICATIONS, NotificationsPayload } from './queries';
import { NoNewNotifications } from './NoNewNotifications';
import { NoNotifications } from './NoNotifications';
import { UnreadNotifications } from './UnreadNotifications';
import { ReadNotifications } from './ReadNotifications';
import { updateNotificationsAsViewed } from './helpers';

export const HeaderNotifications: FC = () => {
  const pageSize = 10;
  const pollInterval = 30000; // 30 seconds
  const isVisible = usePageVisibility();
  // const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasNoMoreNotifications] = useState(false);
  const popover = usePopoverStateAlpha({
    gutter: 20,
    placement: 'bottom-end',
  });

  const { data, refetch, startPolling, stopPolling } = useQuery<NotificationsPayload>(GET_USER_NOTIFICATIONS, {
    variables: { where: { pageSize } },
    fetchPolicy: 'cache-and-network',
  });

  const notifications = useMemo(
    () => data?.myInAppNotificationsPaginated?.notifications || [],
    [
      // eslint-disable-next-line
      data?.myInAppNotificationsPaginated?.notifications,
    ],
  ) as InAppNotificationWithTransaction[];
  const readNotifications = notifications.filter((notif) => notif.viewed);
  const readNotificationsCount = readNotifications.length;
  const unreadNotifications = notifications.filter((notif) => !notif.viewed);
  const unreadNotificationsCount = unreadNotifications.length;
  const hasNoNewNotifications = notifications.length > 0 && unreadNotificationsCount === 0;
  // const isLoadMoreHidden = notifications!.length < pageSize || isLoadingMore || hasNoMoreNotifications;
  // const cursor = data?.myInAppNotificationsPaginated?.cursor;

  const [markNotificationsAsViewed] = useMutation<boolean, OperationVariables>(VIEW_USER_NOTIFICATIONS);

  // Optimistic update for viewed notifications until API resolves this
  const handleReadNotifications = useCallback(async () => {
    if (popover.open) {
      (document.querySelector('[data-test-id="ep-layout"]') as HTMLElement).style.overflowX = 'initial';
      popover.hide();
      await refetch(); // Revisit: ensure query gets called before mutation for notification links
      updateNotificationsAsViewed({ markNotificationsAsViewed, notifications }).catch(() => {
        console.log('failed to mark notification as viewed');
      });
    }
  }, [markNotificationsAsViewed, notifications, popover, refetch]);

  // Polls notification only when browser tab is in view
  if (startPolling && stopPolling) {
    if (isVisible) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }

  return (
    <Box>
      <PopoverTriggerAlpha
        aria-label="notifications"
        state={popover}
        onClick={async () => {
          // Locks Safari horizontal scroll when notification is visible
          (document.querySelector('[data-test-id="ep-layout"]') as HTMLElement).style.overflowX = 'hidden';

          if (!popover.open) {
            trackAction(NotificationTrackingEvents.VIEWED_IN_APP_NOTIFICATIONS);
          }

          if (popover.open) {
            updateNotificationsAsViewed({ markNotificationsAsViewed, notifications }).catch(() => {
              console.log('failed to mark notification as viewed');
            });
          }
        }}
      >
        <Box cursor="pointer" position="relative">
          <Icon color="background" data-test-id="notification-icon" name="BellFilled" size="large" />
          {unreadNotificationsCount > 0 && (
            <Badge
              data-test-id="notification-badge"
              position="absolute"
              right={unreadNotificationsCount > 9 ? '-10px' : '-5px'}
              status="alert"
              top="-5px"
            >
              {unreadNotificationsCount > 9 ? '9+' : unreadNotificationsCount}
            </Badge>
          )}
        </Box>
      </PopoverTriggerAlpha>
      <PopoverAlpha
        aria-label="basic popover"
        flexDirection="column"
        mx="space50"
        p="space0"
        state={{ ...popover, hide: handleReadNotifications }}
        width={{ md: 472 }}
      >
        <Box p="space70" pb="space50">
          <Text size="fontSize40">Notifications</Text>
        </Box>
        <Divider />

        {/* Needed for initial loading */}
        {!data && (
          <Flex alignItems="center" justifyContent="center" py="space70">
            <Loading />
          </Flex>
        )}

        {data && (
          <Box maxHeight={608} overflowY="scroll">
            {hasNoNewNotifications && <NoNewNotifications />}

            {unreadNotificationsCount > 0 && (
              <UnreadNotifications
                handleReadNotifications={handleReadNotifications}
                unreadNotifications={unreadNotifications}
              />
            )}

            {readNotificationsCount > 0 && (
              <ReadNotifications
                handleReadNotifications={handleReadNotifications}
                hasNoMoreNotifications={hasNoMoreNotifications}
                readNotifications={readNotifications}
                unreadNotificationsCount={unreadNotificationsCount}
              />
            )}

            {notifications.length === 0 && <NoNotifications />}

            {/* {isLoadingMore && (
              <Flex alignItems="center" justifyContent="center" py="space70">
                <Loading />
              </Flex>
            )} */}

            {/* <Flex alignItems="center" display={isLoadMoreHidden ? 'none' : 'flex'} justifyContent="center" py="space70">
              <Box
                as="button"
                bg="transparent"
                border="none"
                color="blue500"
                cursor="pointer"
                textDecoration="underline"
                onClick={() => {
                  setIsLoadingMore(true);
                  loadMoreNotificiations({ fetchMore, setIsLoadingMore, setHasNoMoreNotifications, cursor });
                }}
              >
                Load More
              </Box>
            </Flex> */}
          </Box>
        )}
      </PopoverAlpha>
    </Box>
  );
};
