import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock, Divider } from '@endpoint/blockparty';

export const ActivityListSkeleton: FC = () => {
  return (
    <Box bg="white" data-test-id="activity-list-skeleton">
      <SkeletonBlock height={16} maxWidth={234} mb="space30" />
      <Divider mb="space60" />
      <SkeletonBlock height={16} maxWidth={165} mb="space60" />
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Divider mb="space60" />
      <SkeletonBlock height={16} maxWidth={165} mb="space60" />
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Divider mb="space60" />
      <SkeletonBlock height={16} maxWidth={165} mb="space60" />
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Divider mb="space60" />
      <SkeletonBlock height={16} maxWidth={165} mb="space60" />
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Divider mb="space60" />
      <SkeletonBlock height={16} maxWidth={165} mb="space60" />
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Flex flexDirection="row" mb="space60">
        <SkeletonBlock height={12} maxWidth={60} mr="space60" />
        <SkeletonBlock height={12} maxWidth={345} />
      </Flex>
      <Divider mb="space60" />
    </Box>
  );
};
