import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock } from '@endpoint/blockparty';

export const ActivityDetailsSkeleton: FC = () => {
  return (
    <Box bg="white" data-test-id="activity-details-skeleton">
      <Flex flexDirection="column" px="space50" py="22px">
        <SkeletonBlock height={16} maxWidth={128} mb="space30" />
        <SkeletonBlock height={12} maxWidth={231} mb="space60" />
        <SkeletonBlock height={12} maxWidth={201} mb="space30" />
        <SkeletonBlock height={12} maxWidth={187} mb="space30" />
        <SkeletonBlock height={12} maxWidth={187} mb="space30" />
        <SkeletonBlock height={12} maxWidth={231} />
      </Flex>
    </Box>
  );
};
