import React, { useContext } from 'react';
import { Box, Heading, Text, Flex, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';

import { RouteOptions } from '../../Utilities/Constant';
import { CompanyContext, CompanyContextType } from '../../../utils/context';
import { getCompanyEmail } from '../../../utils/getCompanyEmail';
import { getCompanyPhoneNumber } from '../../../utils/getCompanyPhoneNumber';
import { formatPhone } from '../../../utils/formatPhone';

function ForgotEmail() {
  const navigate = useNavigate();
  const { companyName }: CompanyContextType = useContext(CompanyContext);

  return (
    <>
      <Box mb="space80" textAlign="center">
        <Heading as="h2" mb="space40" size="fontSize60">
          Forgot email
        </Heading>

        <Text>
          We’ve got you covered. Please contact us at{' '}
          <Text as="a" color="blue500" href={`mailto:${getCompanyEmail(companyName)}`}>
            {getCompanyEmail(companyName)}
          </Text>{' '}
          or{' '}
          <Text as="a" color="blue500" href={`tel:+1${getCompanyPhoneNumber(companyName).generalInquiries}`}>
            {formatPhone(getCompanyPhoneNumber(companyName).generalInquiries)}
          </Text>{' '}
          and we’ll help get you logged in.
        </Text>
      </Box>
      <Flex flex={1} justifyContent="center" width="100%">
        <Button mb="space70" onClick={() => navigate(RouteOptions.SIGN_IN)}>
          Back to Sign In
        </Button>
      </Flex>
    </>
  );
}

export default ForgotEmail;
