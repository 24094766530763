import React, { FC } from 'react';
import { Box, Flex, Text, Divider } from '@endpoint/blockparty';
import { isToday } from 'date-fns';
import {
  TransactionActivityEntryByDate,
  formatTransactionActivityMilestone,
  formatTransactionActivityText,
} from 'utils/activity';
import { useAppContext } from 'utils/context';
import { formatDate, DAY_OF_WEEK_MONTH_DAY_YEAR, TIME } from 'utils/formatDate';
import { TransactionActivityEntry, TransactionType } from '@endpoint/platform-api-connector/dist/graphql-types';

interface ActivityItemProps {
  transactionActivity: TransactionActivityEntryByDate;
  transactionType?: TransactionType | null;
}

export const ActivityItem: FC<ActivityItemProps> = ({ transactionActivity, transactionType }) => {
  const activityItemDate = new Date(transactionActivity.date);
  const milestoneOfText = [TransactionType.REFINANCE, TransactionType.EQUITY].includes(
    transactionType as TransactionType,
  )
    ? ' of 7: '
    : ' of 8: ';
  const { user } = useAppContext();

  return (
    <>
      <Box py="space60">
        <Text as="h2" data-test-id="activity-heading" fontWeight="semi" mb="space50">
          {isToday(activityItemDate) ? 'Today, ' : null}
          {formatDate(activityItemDate, DAY_OF_WEEK_MONTH_DAY_YEAR)}
        </Text>
        <Box>
          {transactionActivity.activityEntries.map((activity: TransactionActivityEntry) => {
            return (
              <Flex key={activity.id} data-test-id="activity-details" pt="space40">
                <Text flex={1} maxWidth="85px">
                  {formatDate(activity.createdAt, TIME)}
                </Text>
                <Box flex={1}>
                  {activity.milestone && (
                    <Text fontWeight="semi">
                      {`Step ${formatTransactionActivityMilestone(activity.milestone)}${milestoneOfText}`}
                    </Text>
                  )}
                  <Text>{formatTransactionActivityText(activity, user?.id)}</Text>
                </Box>
              </Flex>
            );
          })}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

ActivityItem.displayName = 'ActivityItem';
