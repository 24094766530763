import { MutationFunction, ObservableQuery } from '@apollo/client';
import { InAppNotificationWithTransaction } from '@endpoint/platform-api-connector/dist/graphql-types';

import { GET_USER_NOTIFICATIONS } from './queries';

interface LoadMoreNotificiations {
  fetchMore: ObservableQuery['fetchMore'];
  setIsLoadingMore: (arg0: boolean) => void;
  setHasNoMoreNotifications: (arg0: boolean) => void;
  cursor?: string;
}

export const loadMoreNotificiations = ({
  fetchMore,
  setIsLoadingMore,
  setHasNoMoreNotifications,
  cursor,
}: LoadMoreNotificiations) => {
  return fetchMore({
    query: GET_USER_NOTIFICATIONS,
    variables: { where: { cursor } },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      setIsLoadingMore(false);
      const newCursor = fetchMoreResult?.myInAppNotificationsPaginated.cursor;

      if (!newCursor) {
        setHasNoMoreNotifications(true);
      }

      if (!fetchMoreResult) {
        return previousResult;
      }

      const previousNotifications = previousResult.myInAppNotificationsPaginated.notifications;
      const newNotifications = fetchMoreResult.myInAppNotificationsPaginated.notifications;
      const mergedNotifications = [...previousNotifications, ...newNotifications];
      const result = {
        myInAppNotificationsPaginated: {
          notifications: mergedNotifications,
          cursor: newCursor,
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          __typename: previousResult.myInAppNotificationsPaginated.__typename,
        },
      };

      return result;
    },
  });
};

interface UpdateNotificationsAsViewed {
  markNotificationsAsViewed: MutationFunction;
  notifications: InAppNotificationWithTransaction[];
}

export const updateNotificationsAsViewed = ({
  markNotificationsAsViewed,
  notifications,
}: UpdateNotificationsAsViewed) => {
  return markNotificationsAsViewed({
    update(cache) {
      const viewedNotifications = notifications.map((notif) => ({ ...notif, viewed: true }));

      const newData = {
        myInAppNotificationsPaginated: {
          notifications: viewedNotifications,
        },
      };

      cache.writeQuery({ query: GET_USER_NOTIFICATIONS, data: newData });
    },
  });
};

export const getDocumentNotificationText = (message: string, address: string): string => {
  if (!message.includes(address)) return message;
  const [, messageText] = message.split(address);

  return messageText.replace(':', '').trim();
};
