import React, { FC, useEffect, useContext } from 'react';
import { Heading, Box, RadioGroup, Link, useDisclosure, Divider } from '@endpoint/blockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { Definition } from 'components/form/Definition';
import { Field, useFormikContext } from 'formik';
import { FormikRadio } from 'components/form/FormikBlockparty';
import { trackAction } from 'utils/analytics';
import { TransactionTrackingEvents } from 'consts/analytics';

import { PropertyAddressForm } from './PropertyAddressForm';
import { ParcelNumberForm } from './ParcelNumberForm';
import { FormikValue } from '..';
import { OrderReportContextValue, OrderReportContext } from '../Context';
import { getConfig } from '../../../utils/config';
import { CompanyContext } from '../../../utils/context';
import { ErrorLoadingStatesWidget } from '../../TransactionDetail/Todo/TodoStep/TodoStepComponent/ErrorLoadingStatesWidget';
import { useCountiesOptions } from './hooks/useCountiesOptions';

export const Step1: FC<CurrentStepComponentProps<FormikValue>> = ({ setCurrentStepPosition, formikValue }) => {
  const { CONTACT_US_URL } = getConfig();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isValid, validateForm, values } = useFormikContext<FormikValue>();
  const orderReportContextValue = useContext<OrderReportContextValue>(OrderReportContext);
  const { counties, onSelectState, loading, error } = useCountiesOptions();
  const { companyName } = useContext(CompanyContext);

  useEffect(() => {
    const resolveValidateForm: () => void = async () => {
      await validateForm();
    };

    resolveValidateForm();
  }, [validateForm, values]);

  const orderMethod = values.orderMethod;
  const shouldShowPropertyAddressForm = orderMethod === 'By Property Address';
  const shouldShowParcelNumberForm = orderMethod === 'By Parcel Number';

  function onRadioChange() {
    const { e, form } = this;

    if (e) trackRadioSelection((e.target as HTMLInputElement).value);
    orderReportContextValue.setOrderMethod(e.target.value);

    form.resetForm({});
  }

  const handleDefinitionKeyDown = (e: { keyCode: number }) => {
    const spacebar = e.keyCode === 32;

    if (spacebar) {
      onOpen();
    }
  };

  const trackRadioSelection = (selectionValue: string) => {
    switch (selectionValue) {
      case 'By Property Address': {
        trackAction(TransactionTrackingEvents.REPORT_BY_ADDRESS);
        break;
      }

      case 'By Parcel Number': {
        trackAction(TransactionTrackingEvents.REPORT_BY_PARCEL);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <>
      <Definition
        description="Order a title product on an upcoming listing - sometimes referred to as a preliminary title report, listing package or property report, depending on your market."
        header="Pre-listing report"
        isOpen={isOpen}
        onClose={onClose}
      />
      <Heading as="h1" mb="space50" size="fontSize50">
        Submit a pre-listing report order by entering the property address or parcel number.
      </Heading>
      <Box fontSize="fontSize20" mb="space70">
        Order your{' '}
        <Box
          background="none"
          border="none"
          color="blue500"
          cursor="pointer"
          display="inline"
          fontSize="fontSize20"
          role="button"
          tabIndex={0}
          textDecoration="underline"
          onClick={onOpen}
          onKeyDown={handleDefinitionKeyDown}
        >
          pre-listing report
        </Box>
        .{' '}
        {companyName !== 'AHC' && (
          <>
            Note: {companyName} is currently operating in{' '}
            <Link
              color="blue500"
              data-test-id="contact-us-link"
              fontWeight="normal"
              href={CONTACT_US_URL}
              isExternal
              size="fontSize20"
              textDecoration="underline"
              title="Contact us link"
            >
              select locations
            </Link>
            .
          </>
        )}
      </Box>
      <Box mb="space70">
        <RadioGroup
          aria-label="radio-group-order-method"
          defaultValue={formikValue.orderMethod}
          name="orderMethod"
          role="group"
        >
          <Field
            component={FormikRadio}
            id="By Property Address"
            name="orderMethod"
            value="By Property Address"
            onRadioChange={onRadioChange}
          >
            By Property Address
          </Field>
          <Field
            component={FormikRadio}
            id="By Parcel Number"
            name="orderMethod"
            value="By Parcel Number"
            onRadioChange={onRadioChange}
          >
            By Parcel Number
          </Field>
        </RadioGroup>
      </Box>
      {(shouldShowPropertyAddressForm || shouldShowParcelNumberForm) && <Divider />}
      {!!error || loading ? (
        <ErrorLoadingStatesWidget error={!!error} loading={loading} />
      ) : (
        <Box mt="space70">
          {shouldShowPropertyAddressForm && (
            <PropertyAddressForm counties={counties} formikValue={formikValue} onSelectChange={onSelectState} />
          )}
          {shouldShowParcelNumberForm && (
            <ParcelNumberForm counties={counties} formikValue={formikValue} onSelectChange={onSelectState} />
          )}
        </Box>
      )}
      <NavigationButton goNext={() => setCurrentStepPosition(2)} iconRight="ArrowChevronRight" isDisabled={!isValid}>
        Next
      </NavigationButton>
    </>
  );
};
