import React, { FC, useState } from 'react';
import { Box, Divider, EPContainer, EPContent, EPSidebar, Text } from '@endpoint/blockparty';
import { chunk } from 'lodash';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { AlertMessage } from 'components/AlertMessage';
import { ActivitySkeleton } from 'components/Skeleton';
import { TransactionResponse } from 'custom-types/transactions';
import { transactionActivityByDate, TransactionActivityEntryByDate } from 'utils/activity';
import { TransactionActivityEntry } from '@endpoint/platform-api-connector/dist/graphql-types';
import { PropertyHeader } from 'components/PropertyHeader';

import { GET_TRANSACTION_ACTIVITY } from './queries';
import { ActivityItem } from './ActivityItem';
import { ActivityDetails } from './ActivityDetails';
import { NoActivity } from './NoActivity';
import { ActivityPagination } from './ActivityPagination';

export const Activity: FC = () => {
  const { transactionId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, error } = useQuery<TransactionResponse>(GET_TRANSACTION_ACTIVITY, {
    variables: { where: { id: transactionId } },
  });
  const pages: TransactionActivityEntry[][] = chunk(data?.transaction.myActivityEntries, 12);
  const totalPages = pages.length;

  const handleChange = (_e: any, value: any) => setCurrentPage(value);

  return (
    <>
      <EPContainer gridTemplateRows="max-content">
        {loading && <ActivitySkeleton />}
        {error && (
          <Box data-test-id="activity-error">
            <AlertMessage />
          </Box>
        )}
        {data && data.transaction && (
          <>
            <PropertyHeader fileNum={data.transaction.fileNum} property={data.transaction.property} />

            <EPSidebar>
              <ActivityDetails />
            </EPSidebar>

            {/* Horizontal padding adjusted for Pagination in mobile view */}
            <EPContent px={{ base: 'space0' }}>
              <Box px={{ base: 'space50', lg: 'space80' }}>
                <Box>
                  <Text as="h5" mb="space30" size="fontSize40">
                    Recent Transaction Activity
                  </Text>
                  <Divider />
                </Box>

                <Box minHeight={{ md: '600px' }} overflow="auto">
                  {totalPages ? (
                    transactionActivityByDate(pages[currentPage - 1]).map(
                      (activity: TransactionActivityEntryByDate) => (
                        <ActivityItem
                          key={activity.date}
                          transactionActivity={activity}
                          transactionType={data.transaction.type}
                        />
                      ),
                    )
                  ) : (
                    <NoActivity />
                  )}
                </Box>
              </Box>
              <Box>
                {totalPages && totalPages > 1 && (
                  <ActivityPagination handleChange={handleChange} page={currentPage} totalPages={totalPages} />
                )}
              </Box>
            </EPContent>
          </>
        )}
      </EPContainer>
    </>
  );
};

Activity.displayName = 'Activity';
