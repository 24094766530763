import React, { FC, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { ContactType, ContactWhereUniqueInput, CreateContactInput } from '@endpoint/endpoint-bff-graphql-schema';
import { Heading, Box, Button, RadioGroup, Flex, useToast, Text, Link } from '@endpoint/blockparty';
import { AppContext, AppContextType, CompanyContext } from 'utils/context';
import { Field, Formik, Form } from 'formik';
import { object, string } from 'yup';
import { FormikRadio } from 'components/form/FormikBlockparty';
import { useContact } from 'hooks/useContact';
import Toast from 'Auth/Components/Toast';
import { formatPhoneNumberWithOptionalIntlCode } from 'utils/formatPhone';
import { isSsoUser } from 'utils/singleSignOn';
import { getConfig } from 'utils/config';
import { CompanyName } from 'utils/whiteLabel/types';
import { useAuth } from 'hooks/auth/useAuth';
import { localStorageGetItem } from 'utils/localStorageAccessor';
import { SSO_USER_INFO_KEY } from 'utils/auth/storage';
import {
  ButtonClickedTrackingPayload,
  GenericTrackingEvents,
  SignUpTrackingEvents,
  TrackingEventType,
  TRACK_GENERIC_ERROR_VIEWED_PAYLOAD,
} from 'consts/analytics';
import { trackAction, trackIdentityPreCreation } from 'utils/analytics';

import { getContactType, getPhoneNumber, isNewLoginSystemEnabled } from '../../Utilities/helper';

export interface ISignUpRoleValues {
  contactType: string;
}

export const SignUpRoleSchema = object().shape({
  contactType: string().required('Role is Required'),
});

const roleOptions = [
  {
    name: 'Buyer',
    value: 'buyer',
  },
  {
    name: 'Seller',
    value: 'seller',
  },
  {
    name: 'Borrower',
    value: 'borrower',
  },
  {
    name: 'Real Estate Agent',
    value: 'realEstateAgent',
  },
  {
    name: 'Assistant or Transaction Coordinator',
    value: 'assistantOrTransactionCoordinator',
  },
  {
    name: 'Mortgage Broker',
    value: 'mortgageBroker',
  },
  {
    name: 'Lender',
    value: 'lender',
  },
  {
    name: 'Loan Officer',
    value: 'loanOfficer',
  },
];

const getRoleOptions = (companyName: CompanyName, isSso: boolean) => {
  if (companyName === 'AHC' && isSso) {
    return roleOptions.filter((roleOption) =>
      ['realEstateAgent', 'assistantOrTransactionCoordinator'].includes(roleOption.value),
    );
  }

  return roleOptions;
};

const SignUpRole: FC = () => {
  const newLoginSystemEnabled = isNewLoginSystemEnabled();
  const { data } = useAuth();
  const { companyName } = useContext(CompanyContext);
  const { authFields, setAuthFields, setUser, user }: AppContextType = useContext(AppContext);
  const { PRIVACY_URL } = getConfig();
  const SUBMIT_BUTTON_TEXT = 'Continue';

  const { createContact, createContactResult, createContactLoading, createContactError } = useContact();
  const { updateContact, updateContactLoading, updateContactResult, updateContactError } = useContact();
  const [isSso, setIsSso] = useState<boolean>(false);

  const toast = useToast();

  const getUserEmailAndPhone = async () => {
    // for sign up flow retrieve the phone from Cognito auth field
    // for sign in flow retrieve the phone from getMe query if it does not exist in getMe get phone from Cognito user attributes
    const phone = data?.phone || getPhoneNumber(authFields.phone);

    const email = data?.username || authFields.email;

    if (phone && email) {
      return { email, phone };
    }

    if (!newLoginSystemEnabled) {
      // if user context will be lost (e.g. user reload page during onboarding flow)
      // call amplify to get Cognito user info
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        setAuthFields({ ...authFields, user: currentUser });

        const formattedPhone = formatPhoneNumberWithOptionalIntlCode(currentUser.attributes?.phone_number);

        return { email: currentUser.attributes?.email, phone: formattedPhone };
      } catch (error: any) {
        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorCode: error.code });
      }
    }

    if (localStorageGetItem(SSO_USER_INFO_KEY)) {
      const localStorageSSOUserInfo = JSON.parse(localStorageGetItem(SSO_USER_INFO_KEY) ?? '{}');

      return { email: localStorageSSOUserInfo.email, phone: localStorageSSOUserInfo.phoneNumber };
    }

    return { email: null, phone: null };
  };

  const handleRoleSubmit = async (values: ISignUpRoleValues) => {
    const contactType: ContactType = getContactType(values.contactType);
    const { email, phone } = await getUserEmailAndPhone();

    const trackSelectRoleSubmitProperties: ButtonClickedTrackingPayload = {
      name: TrackingEventType.BUTTON_CLICKED,
      buttonId: SUBMIT_BUTTON_TEXT,
    };
    const createContactInput: CreateContactInput = {
      email,
      phone: phone || '0000000000', // if sso, phone number won't exist yet
      contactType,
    };

    trackAction(SignUpTrackingEvents.CONTINUE_BUTTON_SUBMITTED, trackSelectRoleSubmitProperties);
    trackIdentityPreCreation({ onboardingRole: values.contactType });

    if (user.id) {
      const contactWhereUniqueInput: ContactWhereUniqueInput = {
        id: user.id,
      };

      await updateContact(contactWhereUniqueInput, { contactType });
    } else {
      await createContact(createContactInput);
    }
  };

  useEffect(() => {
    async function getCognitoUser() {
      const currentUser = await Auth.currentAuthenticatedUser();

      if (isSsoUser(currentUser)) {
        setIsSso(true);
      }
    }

    // TODO: revisit after https://apex-venture.atlassian.net/browse/PX-3177
    if (!isNewLoginSystemEnabled()) {
      void getCognitoUser();
    } else if (localStorageGetItem(SSO_USER_INFO_KEY)) {
      setIsSso(true);
    }
  }, []);

  useEffect(() => {
    if (!createContactLoading && createContactResult) {
      const { email, phone, contactType, id } = createContactResult.createContact;

      setUser({ ...user, email, phone, contactType, id, isOnboarded: false });
    }

    if (!createContactLoading && createContactError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={createContactError.message} onClose={onClose} />,
      });

      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message: `createContact mutation error -- Select role screen / Onboarding. User id: ${
          user.id
        }. Error: ${JSON.stringify(createContactError)}}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createContactLoading, createContactResult, createContactError]);

  useEffect(() => {
    if (!updateContactLoading && updateContactResult) {
      setUser({ ...user, ...updateContactResult.updateContact });
    }

    if (!updateContactLoading && updateContactError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={updateContactError.message} onClose={onClose} />,
      });

      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message: `updateContact mutation error -- Select role screen / Onboarding. User id: ${
          user.id
        }. Error: ${JSON.stringify(updateContactError)}}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContactLoading, updateContactResult, updateContactError]);

  return (
    <Formik initialValues={{ contactType: '' }} validationSchema={SignUpRoleSchema} onSubmit={handleRoleSubmit}>
      {({ isValid, dirty, values }) => (
        <Form>
          <Heading as="h2" mb="space50" size={{ base: 'fontSize70', md: 'fontSize60' }}>
            Please select your role
          </Heading>

          <Box fontSize="fontSize20" lineHeight="lineHeight20" mb="space70">
            Welcome to {companyName}! Please select your role from the choices below.
          </Box>

          <Box mb="space30">
            <RadioGroup
              aria-label="sign-up-role"
              defaultValue={values.contactType}
              mb="space80"
              name="contactType"
              role="group"
              spacing="space50"
            >
              {getRoleOptions(companyName, isSso).map(({ name, value }) => {
                return (
                  <Field
                    key={name}
                    component={FormikRadio}
                    id={name}
                    mb={{ base: 'space50', lg: 'space0' }}
                    name="contactType"
                    value={value}
                  >
                    {name}
                  </Field>
                );
              })}
            </RadioGroup>
          </Box>

          {isSso && (
            <>
              <Box mb="space80">
                <Text>
                  By clicking “Continue”, you agree to {companyName}’s{' '}
                  <Link color="blue500" cursor="pointer" href={PRIVACY_URL} target="_blank" textDecoration="underline">
                    Privacy & Terms
                  </Link>
                </Text>
              </Box>
            </>
          )}

          <Flex
            backgroundColor="white"
            bottom={{ base: 0, md: 'initial' }}
            boxShadow={{ base: 'medium', md: 'none' }}
            justifyContent="flex-end"
            left={{ base: 0, md: 'initial' }}
            position={{ base: 'fixed', md: 'initial' }}
            px={{ base: 'space50', md: 'space0' }}
            py={{ base: 'space30', md: 'space0' }}
            right={{ base: 0, md: 'initial' }}
            zIndex="overlay"
          >
            <Button isDisabled={!(isValid && dirty)} isLoading={createContactLoading} type="submit">
              {SUBMIT_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpRole;
