import React, { FC } from 'react';
import { Divider, Box, Heading } from '@endpoint/blockparty';
import { InAppNotificationWithTransaction } from '@endpoint/platform-api-connector/dist/graphql-types';

import { Notification } from '../Notification';

interface UnreadNotificationsProps {
  handleReadNotifications: () => void;
  unreadNotifications: InAppNotificationWithTransaction[];
}

export const UnreadNotifications: FC<UnreadNotificationsProps> = ({ handleReadNotifications, unreadNotifications }) => (
  <>
    <Box data-test-id="unread-notifications" pt="space60">
      <Heading color="carbon500" mb="space30" mx="space70" size="fontSize10">
        UNREAD
      </Heading>
      {unreadNotifications.map((notif) => (
        <Notification
          key={notif.id}
          handleReadNotifications={handleReadNotifications}
          isPrincipalUser={false}
          notification={notif}
        />
      ))}
    </Box>
    <Divider />
  </>
);
