import React, { FC, useState } from 'react';
import { InAppNotificationWithTransaction } from '@endpoint/platform-api-connector/dist/graphql-types';
import { Avatar, Flex, Text, ListChoice, Link, Box, Icon } from '@endpoint/blockparty';
import { isToday, format } from 'date-fns';
import { PLACEHOLDER_MLS } from 'consts/assets';
import { convertLinkToNewPath, removeUndefinedFromString } from 'utils/notifications';
import { useNavigate } from 'react-router-dom';
import { trackAction } from 'utils/analytics';
import { NotificationTrackingEvents } from 'consts/analytics';
import { useAppContext } from 'utils/context';
import { useNumberOfTransactions } from 'hooks/useNumberOfTransactions';

import { getDocumentNotificationText } from '../helpers';

interface NotificationProps {
  handleReadNotifications: () => void;
  notification: InAppNotificationWithTransaction;
  isPrincipalUser?: boolean;
}

export const Notification: FC<NotificationProps> = ({
  handleReadNotifications,
  notification,
  isPrincipalUser = false,
}) => {
  const [isShown, setIsShown] = useState(false);
  const { link, text, createdAt, viewed, transaction, principalId } = notification;
  const { user } = useAppContext();
  const { propertyImage, address } = transaction?.property || {};
  const { standardFormat: standardAddressFormat } = address || {};
  const cleanedStandardAddressFormat = removeUndefinedFromString(standardAddressFormat);
  const date = new Date(createdAt);
  const isNotificationDateToday = isToday(date);
  const { numberOfTransactions } = useNumberOfTransactions();
  const isSingleTransaction = numberOfTransactions === 1;
  const message =
    text && cleanedStandardAddressFormat ? getDocumentNotificationText(text, cleanedStandardAddressFormat) : text;
  const navigate = useNavigate();

  const handleNotificationLink = (notificationLink: string) => {
    const newLink = convertLinkToNewPath(notificationLink);

    handleReadNotifications();
    navigate(newLink);
  };

  return (
    <ListChoice
      data-test-id="notification"
      display="flex"
      px="space70"
      py="space40"
      style={(user.id !== principalId && { cursor: 'default' }) || undefined}
      onClick={() => {
        if (user.id === principalId) {
          trackAction(NotificationTrackingEvents.IN_APP_NOTIFICATIONS_CLICKED);
          link && handleNotificationLink(link);
        }
      }}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Avatar name="Property Image" src={propertyImage || PLACEHOLDER_MLS} />
      <Flex flex={1} flexDirection="column" ml="space50">
        {!isSingleTransaction && (
          <Text data-test-id="property-address" fontWeight="semi" size="fontSize10">
            {cleanedStandardAddressFormat}
          </Text>
        )}
        {!isPrincipalUser ? (
          <Text
            data-test-id="non-principal-user-text"
            display="inline-block"
            fontWeight={cleanedStandardAddressFormat ? 'normal' : 'semi'}
            size="fontSize10"
            textDecoration={user.id === principalId && isShown ? 'underline' : undefined}
          >
            {message}
          </Text>
        ) : (
          <Link
            color="blue500"
            data-test-id="principal-user-link"
            href={link || undefined}
            size="fontSize10"
            textDecoration="underline"
          >
            {link}
          </Link>
        )}

        <Text color="carbon500" mt="space20" size="fontSize10">
          {isNotificationDateToday && 'Today, '}
          {format(date, 'MMM d, h:mma')}
        </Text>
      </Flex>
      {!viewed && (
        <Box data-test-id="viewed-icon" flex="0 0 auto">
          <Icon color="turquoise500" name="CircleSmall" size="medium" />
        </Box>
      )}
    </ListChoice>
  );
};
