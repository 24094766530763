import React from 'react';
import { Box, Heading, Text } from '@endpoint/blockparty';

export const ActivityDetails = () => (
  <Box bg="background" data-test-id="activity-details" px="space50" py="space60">
    <Heading size="fontSize50">Activity</Heading>
    <Box>
      <Text as="p" mb="space60" mt="space50">
        All activity from you and your party will be displayed here.
      </Text>
      <Text as="p">
        You can view transaction specific activity and related notifications that have occurred in the past for both
        milestone and non-milestone activities.
      </Text>
    </Box>
  </Box>
);

ActivityDetails.displayName = 'ActivityDetails';
