import { CompanyName } from 'utils/whiteLabel/types';

type CompanyPhoneNumber = {
  [key in CompanyName]: {
    generalInquiries: string;
    technicalSupport: string;
  };
};

const companyPhoneNumbers: CompanyPhoneNumber = {
  Endpoint: {
    generalInquiries: '8662307571',
    technicalSupport: '4158434110',
  },
  AHC: {
    generalInquiries: '8665852046',
    technicalSupport: '8665852047',
  },
  OpsTest: {
    // copied from endpoint
    generalInquiries: '8662307571',
    technicalSupport: '4158434110',
  },
  QETestOrg: {
    generalInquiries: '8662307571',
    technicalSupport: '4158434110',
  },
  QETestOrg2: {
    generalInquiries: '8662307571',
    technicalSupport: '4158434110',
  },
};

export const getCompanyPhoneNumber = (key: CompanyName) => {
  return companyPhoneNumbers[key];
};
