import React, { FC } from 'react';
import { Flex, Text } from '@endpoint/blockparty';

export const EndOfNotifications: FC = () => (
  <Flex justifyContent="center" py="space70">
    <Text color="carbon500" size="fontSize10">
      You’re all caught up!
    </Text>
  </Flex>
);
