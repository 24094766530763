import { TransactionActivityEntry, DateTime } from '@endpoint/platform-api-connector/dist/graphql-types';
import { chain } from 'lodash';

export interface TransactionActivityEntryByDate {
  date: DateTime;
  activityEntries: TransactionActivityEntryWithDateStrings[];
}

export interface TransactionActivityEntryWithDateStrings extends TransactionActivityEntry {
  createdAtDateString: string;
}

export const comparableTransactionActivities = (
  myTransactionActivity: TransactionActivityEntry[],
): TransactionActivityEntryWithDateStrings[] => {
  const updatedTransactionActivities: TransactionActivityEntryWithDateStrings[] = [];

  myTransactionActivity.forEach((activity: TransactionActivityEntry) => {
    const createdAtDateString = new Date(activity.createdAt).toDateString();

    updatedTransactionActivities.push({ ...activity, createdAtDateString });
  });

  return updatedTransactionActivities;
};

export const transactionActivityByDate = (
  myTransactionActivity: TransactionActivityEntry[],
): TransactionActivityEntryByDate[] => {
  const transactionActivityWithDateStrings: TransactionActivityEntryWithDateStrings[] =
    comparableTransactionActivities(myTransactionActivity);

  return chain(transactionActivityWithDateStrings)
    .groupBy('createdAtDateString')
    .map((value, key) => ({
      date: key,
      activityEntries: value,
    }))
    .value();
};

export const formatTransactionActivityMilestone = (milestone: string | undefined): string | undefined => {
  if (!milestone) {
    return milestone;
  }

  // sometimes milestone comes through with extra double quotes, remove those
  const milestoneWithoutQuotes: string | undefined = milestone.replace(/['"]+/g, '');

  // sometimes milestones have letters in the second position for legacy reasons
  return milestoneWithoutQuotes[0];
};

export const formatTransactionActivityText = (
  activityEntry: TransactionActivityEntry,
  userId: string | undefined | null,
): string => {
  // newer activity entries will have this in the text, but this covers older ones
  if (activityEntry.text.slice(-15) === 'To-Do completed') {
    const principalName = userId === activityEntry.principalId ? 'you' : activityEntry.principalName;

    return `${activityEntry.text} by ${principalName}`;
  }

  return activityEntry.text;
};
