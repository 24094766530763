import React, { FC } from 'react';
import { Divider, Box, Heading } from '@endpoint/blockparty';
import { InAppNotificationWithTransaction } from '@endpoint/platform-api-connector/dist/graphql-types';

import { EndOfNotifications } from '../EndOfNotifications';
import { Notification } from '../Notification';

interface ReadNotificationsProps {
  handleReadNotifications: () => void;
  hasNoMoreNotifications: boolean;
  readNotifications: InAppNotificationWithTransaction[];
  unreadNotificationsCount: number;
}

export const ReadNotifications: FC<ReadNotificationsProps> = ({
  handleReadNotifications,
  hasNoMoreNotifications,
  readNotifications,
  unreadNotificationsCount,
}) => (
  <Box data-test-id="read-notifications" pt="space60">
    <Heading color="carbon500" mb="space30" mx="space70" size="fontSize10">
      {unreadNotificationsCount ? 'OLDER' : 'LATEST'}
    </Heading>
    {readNotifications.map((notif) => (
      <Notification
        key={notif.id}
        handleReadNotifications={handleReadNotifications}
        isPrincipalUser={false}
        notification={notif}
      />
    ))}
    <Divider />
    {hasNoMoreNotifications && <EndOfNotifications />}
  </Box>
);
