import * as React from 'react';
import { render } from 'react-dom';
import Amplify from 'aws-amplify';
import { BlockPartyProvider } from '@endpoint/blockparty';
import { initSentry } from 'utils/sentry';
import { CONFIG_ID, getConfig } from 'utils/config';
import { CustomStorage } from 'utils/auth/storage';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { CompanyContext, CompanyContextType } from 'utils/context';
import { removeExtraConfig, toCompanyName } from 'utils/whiteLabel';
import { BrowserRouter } from 'react-router-dom';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';
import useOrganization from 'hooks/useOrganization';
import { FC } from 'react';
import { NativeAppFallback } from 'components/NativeAppFallback';
import { getTheme } from 'themes';

import App from './App';

const config: AppConfig = getConfig();

(async (): Promise<void> => {
  if (!config) {
    console.error('!!! MISSING ENV CONFIG !!!');
    render(<h1>Missing Configuration. Please contact support.</h1>, document.getElementById('root'));
  } else {
    const isMTEPSignInSignUpEnabled = isNewLoginSystemEnabled();

    removeExtraConfig(CONFIG_ID);

    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    isMTEPSignInSignUpEnabled
      ? null
      : Amplify.configure({
          Auth: {
            region: config.AWS_REGION,
            userPoolId: config.AWS_COGNITO_USER_POOL_ID,
            userPoolWebClientId: config.AWS_COGNITO_WEB_CLIENT_ID,
            storage: CustomStorage,
            oauth: {
              domain: config.AWS_COGNITO_USER_POOL_DOMAIN,
              scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: `${baseUrl}/`,
              redirectSignOut: `${baseUrl}/signout`,
              responseType: 'code',
            },
          },
        });

    initSentry(config);

    const LDProvider = await asyncWithLDProvider({
      clientSideID: config.LAUNCH_DARKLY_CLIENT_ID ?? '',
    });

    const AppWrapper: FC = () => {
      const organization = useOrganization();
      const companyName = toCompanyName(organization.shortName);
      const CompanyContextValue: CompanyContextType = { companyName };
      const theme = getTheme(companyName);

      return (
        <React.StrictMode>
          <LDProvider>
            <CompanyContext.Provider value={CompanyContextValue}>
              <BlockPartyProvider theme={theme}>
                <BrowserRouter>
                  <NativeAppFallback>
                    <App />
                  </NativeAppFallback>
                </BrowserRouter>
              </BlockPartyProvider>
            </CompanyContext.Provider>
          </LDProvider>
        </React.StrictMode>
      );
    };

    render(<AppWrapper />, document.getElementById('root'));
  }
})();
